var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('Notification',{ref:"ok_noti",attrs:{"type":"success","message":"Información actualizada"}}),_c('Notification',{ref:"error_noti",attrs:{"type":"error","message":"Error"}}),_c('v-card',{attrs:{"loading":_vm.isloading}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('v-card-text',[_c('form',{staticClass:"d-flex flex-column flex-md-row"},[_c('div',{staticClass:"d-flex flex-column ma-1",staticStyle:{"width":"100%"}},[_c('v-text-field',{attrs:{"label":"Nombre","error-messages":_vm.nombreErrors,"required":"","disabled":_vm.isEdit},on:{"input":() => {
                        _vm.input();
                        _vm.$v.nombre.$touch();
                     },"blur":function($event){return _vm.$v.nombre.$touch()}},model:{value:(_vm.nombre),callback:function ($$v) {_vm.nombre=$$v},expression:"nombre"}}),_c('v-text-field',{attrs:{"label":"Username","error-messages":_vm.usernameErrors,"required":"","disabled":_vm.isEdit},on:{"input":() => {
                        _vm.input();
                        _vm.$v.username.$touch();
                     },"blur":function($event){return _vm.$v.username.$touch()}},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c('v-text-field',{attrs:{"label":"Contraseña","error-messages":_vm.passwordErrors,"required":"","append-icon":_vm.show_password ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.show_password ? 'text' : 'password'},on:{"input":() => {
                        _vm.input();
                        _vm.$v.password.$touch();
                     },"blur":function($event){return _vm.$v.password.$touch()},"click:append":function($event){_vm.show_password = !_vm.show_password}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('v-text-field',{attrs:{"error-messages":_vm.repeat_passwordErrors,"label":"Confirmar contraseña","required":"","append-icon":_vm.show_repeat ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.show_repeat ? 'text' : 'password'},on:{"input":function($event){return _vm.$v.repeat_password.$touch()},"blur":function($event){return _vm.$v.repeat_password.$touch()},"click:append":function($event){_vm.show_repeat = !_vm.show_repeat}},model:{value:(_vm.repeat),callback:function ($$v) {_vm.repeat=$$v},expression:"repeat"}})],1),_c('div',{staticClass:"d-flex flex-column ma-1",staticStyle:{"width":"100%"}},[_c('v-text-field',{attrs:{"label":"Empresa","error-messages":_vm.empresaErrors,"disabled":_vm.isEdit,"required":""},on:{"input":() => {
                        _vm.input();
                        _vm.$v.empresa.$touch();
                     },"blur":function($event){return _vm.$v.empresa.$touch()}},model:{value:(_vm.empresa),callback:function ($$v) {_vm.empresa=$$v},expression:"empresa"}}),_c('div',{staticClass:"d-flex align-center justify-space-around"},[_c('v-switch',{attrs:{"true-value":"S","false-value":"N","label":"Estatus","disabled":!_vm.isEdit},on:{"input":_vm.input},model:{value:(_vm.estatus),callback:function ($$v) {_vm.estatus=$$v},expression:"estatus"}}),_c('v-switch',{attrs:{"true-value":"S","false-value":"N","label":"Microsip"},on:{"input":_vm.input},model:{value:(_vm.microsip),callback:function ($$v) {_vm.microsip=$$v},expression:"microsip"}})],1),_c('v-select',{attrs:{"label":"Vendedor","items":_vm.vendedores,"error-messages":_vm.vendedorErrors,"required":""},on:{"input":() => {
                        _vm.input();
                        _vm.$v.vendedor.$touch();
                     },"blur":function($event){return _vm.$v.vendedor.$touch()}},model:{value:(_vm.vendedor),callback:function ($$v) {_vm.vendedor=$$v},expression:"vendedor"}}),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Suscripcion","append-icon":"mdi-calendar","readonly":"","error-messages":_vm.suscripcionErrors,"required":""},on:{"input":() => {
                              _vm.input();
                              _vm.$v.suscripcion.$touch();
                           },"blur":function($event){return _vm.$v.suscripcion.$touch()}},model:{value:(_vm.suscripcion),callback:function ($$v) {_vm.suscripcion=$$v},expression:"suscripcion"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.show_datePicker),callback:function ($$v) {_vm.show_datePicker=$$v},expression:"show_datePicker"}},[_c('v-date-picker',{on:{"input":function($event){_vm.show_datePicker = false}},model:{value:(_vm.suscripcion),callback:function ($$v) {_vm.suscripcion=$$v},expression:"suscripcion"}})],1)],1)])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.close}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.save}},[_vm._v("Guardar")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }