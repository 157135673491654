<template>
   <v-dialog v-model="dialog" max-width="600px">
      <Notification type="success" message="Información actualizada" ref="ok_noti" />
      <Notification type="error" message="Error" ref="error_noti" />
      <v-card :loading="isloading">
         <v-card-title>
            {{ title }}
         </v-card-title>
         <v-card-text>
            <form class="d-flex flex-column flex-md-row">
               <div class="d-flex flex-column ma-1" style="width: 100%">
                  <v-text-field
                     @input="
                        () => {
                           input();
                           $v.nombre.$touch();
                        }
                     "
                     v-model="nombre"
                     label="Nombre"
                     :error-messages="nombreErrors"
                     required
                     :disabled="isEdit"
                     @blur="$v.nombre.$touch()"
                  ></v-text-field>
                  <v-text-field
                     @input="
                        () => {
                           input();
                           $v.username.$touch();
                        }
                     "
                     v-model="username"
                     label="Username"
                     :error-messages="usernameErrors"
                     required
                     :disabled="isEdit"
                     @blur="$v.username.$touch()"
                  ></v-text-field>
                  <v-text-field
                     @input="
                        () => {
                           input();
                           $v.password.$touch();
                        }
                     "
                     v-model="password"
                     label="Contraseña"
                     :error-messages="passwordErrors"
                     required
                     @blur="$v.password.$touch()"
                     :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                     :type="show_password ? 'text' : 'password'"
                     @click:append="show_password = !show_password"
                  ></v-text-field>
                  <v-text-field
                     v-model="repeat"
                     :error-messages="repeat_passwordErrors"
                     label="Confirmar contraseña"
                     required
                     @input="$v.repeat_password.$touch()"
                     @blur="$v.repeat_password.$touch()"
                     :append-icon="show_repeat ? 'mdi-eye' : 'mdi-eye-off'"
                     :type="show_repeat ? 'text' : 'password'"
                     @click:append="show_repeat = !show_repeat"
                  ></v-text-field>
               </div>
               <div class="d-flex flex-column ma-1" style="width: 100%">
                  <v-text-field
                     @input="
                        () => {
                           input();
                           $v.empresa.$touch();
                        }
                     "
                     v-model="empresa"
                     label="Empresa"
                     :error-messages="empresaErrors"
                     :disabled="isEdit"
                     required
                     @blur="$v.empresa.$touch()"
                  ></v-text-field>
                  <div class="d-flex align-center justify-space-around">
                     <v-switch @input="input" v-model="estatus" true-value="S" false-value="N" label="Estatus" :disabled="!isEdit"></v-switch>
                     <v-switch @input="input" v-model="microsip" true-value="S" false-value="N" label="Microsip"></v-switch>
                  </div>
                  <v-select
                     @input="
                        () => {
                           input();
                           $v.vendedor.$touch();
                        }
                     "
                     v-model="vendedor"
                     label="Vendedor"
                     :items="vendedores"
                     :error-messages="vendedorErrors"
                     required
                     @blur="$v.vendedor.$touch()"
                  ></v-select>
                  <v-menu
                     v-model="show_datePicker"
                     :close-on-content-click="false"
                     :nudge-right="40"
                     transition="scale-transition"
                     offset-y
                     min-width="auto"
                  >
                     <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                           v-model="suscripcion"
                           label="Suscripcion"
                           append-icon="mdi-calendar"
                           readonly
                           v-bind="attrs"
                           v-on="on"
                           @input="
                              () => {
                                 input();
                                 $v.suscripcion.$touch();
                              }
                           "
                           :error-messages="suscripcionErrors"
                           required
                           @blur="$v.suscripcion.$touch()"
                        ></v-text-field>
                     </template>
                     <v-date-picker v-model="suscripcion" @input="show_datePicker = false"></v-date-picker>
                  </v-menu>
               </div>
            </form>
         </v-card-text>
         <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close">Cancelar</v-btn>
            <v-btn color="primary" @click="save">Guardar</v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength, minLength, alphaNum } from "vuelidate/lib/validators";
import Notification from "../../Notification.vue";
import moment, { min } from "moment";

export default {
   components: {
      Notification,
   },
   mixins: [validationMixin],
   validations: {
      nombre: {
         required,
         minLength: minLength(5),
         maxLength: maxLength(50),
         name: function () {
            return /^[a-zA-Z_ ]*$/.test(this.nombre);
         },
      },
      username: {
         alphaNum,
         required,
         maxLength: maxLength(50),
         minLength: minLength(10),
      },
      password: {
         requirePass: function () {
            if (this.password.trim().length > 0) {
               return true;
            }
            if (this.isEdit) {
               return true;
            }
            return false;
         },
         minLength: minLength(10),
         maxLength: maxLength(30),
      },
      repeat_password: {
         sameAsPassword: function () {
            if (this.password == this.repeat) {
               return true;
            } else {
               return false;
            }
         },
      },
      empresa: { maxLength: maxLength(50), required },
      vendedor: { required },
      suscripcion: {
         date: function () {
            if (moment(this.suscripcion).isValid()) {
               if (moment(this.suscripcion).isAfter(moment())) {
                  return true;
               }
            }
            return false;
         },
         required,
      },
   },
   props: {
      value: Object,
      title: String,
   },
   model: {
      prop: "value",
      event: "input",
   },
   data: function () {
      return {
         id_usuario: this.value.id_usuario,
         username: this.value.username,
         nombre: this.value.nombre,
         empresa: this.value.empresa,
         estatus: this.value.estatus,
         vendedor: this.value.vendedor,
         suscripcion: this.value.suscripcion,
         microsip: this.value.microsip,
         password: "",
         repeat: "",
         show_password: false,
         show_repeat: false,
         dialog: false,
         isloading: false,
         show_datePicker: false,
         vendedores: [],
      };
   },
   watch: {
      dialog: function (val) {
         if (!val) {
            this.close();
            this.$emit("close");
         }
      },
   },
   methods: {
      save: async function () {
         try {
            this.$v.$touch();
            if (!this.$v.$invalid) {
               this.isloading = true;
               let res = null;
               if (!this.isEdit) {
                  res = await this.$store.dispatch("insertCuenta", {
                     username: this.username,
                     password: this.password,
                     nombre: this.nombre,
                     empresa: this.empresa,
                     vendedor: this.vendedor,
                     suscripcion: this.suscripcion,
                     microsip: this.microsip == "S" ? "S" : "N",
                  });
               } else {
                  res = await this.$store.dispatch("updateCuenta", {
                     id: this.id_usuario,
                     password: this.password,
                     vendedor: this.vendedor,
                     suscripcion: this.suscripcion,
                     estatus: this.estatus == "S" ? "S" : "N",
                     microsip: this.microsip == "S" ? "S" : "N",
                  });
               }
               if (res == true) {
                  this.$refs.ok_noti.Show();
                  this.$emit("save");
                  this.close();
               } else {
                  throw res;
               }
            } else {
               throw "error";
            }
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.isloading = false;
         }
      },
      close: function () {
         this.dialog = false;
         this.$v.$reset();
      },
      show: function (i) {
         this.id_usuario = i.id_usuario;
         this.username = i.username;
         this.nombre = i.nombre;
         this.empresa = i.empresa;
         this.estatus = i.estatus;
         this.vendedor = i.vendedor;
         this.suscripcion = moment(i.suscripcion).format("YYYY-MM-DD");
         this.microsip = i.microsip;
         this.password = "";
         this.repeat = "";
         this.dialog = true;

         let sellers = [];
         this.$store.state.sellers.forEach((element) => {
            sellers.push(element.nombre);
         });
         this.vendedores = sellers;
      },
      input: function (e) {
         this.$emit("input", {
            id_usuario: this.id_usuario,
            username: this.username,
            nombre: this.nombre,
            empresa: this.empresa,
            estatus: this.estatus,
            vendedor: this.vendedor,
            suscripcion: moment(this.suscripcion).format("YYYY-MM-DD"),
            microsip: this.microsip,
         });
      },
   },
   computed: {
      isEdit() {
         if (this.id_usuario) {
            return true;
         } else {
            return false;
         }
      },
      nombreErrors() {
         const errors = [];
         if (!this.$v.nombre.$dirty) return errors;
         !this.$v.nombre.required && errors.push("El nombre es requerido");
         !this.$v.nombre.name && errors.push("Debe contener caracteres alfabeticos");
         !this.$v.nombre.maxLength && errors.push("Debe contener maximo 50 caracteres");
         !this.$v.nombre.minLength && errors.push("Debe contener minimo 5 caracteres");
         return errors;
      },
      usernameErrors() {
         const errors = [];
         if (!this.$v.username.$dirty) return errors;
         !this.$v.username.required && errors.push("El username es requerido");
         !this.$v.username.maxLength && errors.push("Debe contener maximo 50 caracteres");
         !this.$v.username.minLength && errors.push("Debe contener minimo 10 caracteres");
         !this.$v.username.alphaNum && errors.push("Debe contener caracteres alfanumericos");
         return errors;
      },
      passwordErrors() {
         const errors = [];
         if (!this.$v.password.$dirty) return errors;
         !this.$v.password.requirePass && errors.push("La contraseña es requerida");
         !this.$v.password.minLength && errors.push("Debe contener minimo 10 caracteres");
         !this.$v.password.maxLength && errors.push("Debe contener maximo 30 caracteres");
         return errors;
      },
      repeat_passwordErrors() {
         const errors = [];
         if (!this.$v.repeat_password.$dirty) return errors;
         !this.$v.repeat_password.sameAsPassword && errors.push("Las contraseñas no son iguales");
         return errors;
      },
      empresaErrors() {
         const errors = [];
         if (!this.$v.empresa.$dirty) return errors;
         !this.$v.empresa.maxLength && errors.push("Debe contener maximo 50 caracteres");
         !this.$v.empresa.required && errors.push("Requerido");
         return errors;
      },
      vendedorErrors() {
         const errors = [];
         if (!this.$v.vendedor.$dirty) return errors;
         !this.$v.vendedor.required && errors.push("Requerido");
         return errors;
      },
      suscripcionErrors() {
         const errors = [];
         if (!this.$v.suscripcion.$dirty) return errors;
         !this.$v.suscripcion.required && errors.push("Requerido");
         !this.$v.suscripcion.date && errors.push("Fecha no valida");
         return errors;
      },
   },
};
</script>
