<template>
   <div>
      <Notification type="success" message="Información actualizada" ref="ok_noti" />
      <Notification type="error" message="Error" ref="error_noti" />
      <Account v-model="editedItem" :title="title" ref="account" @save="save" @close="close" />
      <Dialog message="¿Eliminar cuenta?" ref="delete" @result_ok="deleteItemConfirm" />
      <v-data-table
         :headers="headers"
         :items="rows"
         :options.sync="options"
         :loading="loading"
         :footer-props="{
            'items-per-page-options': [10, 30, 50, 100],
         }"
         :page.sync="page"
         class="ma-3"
         :server-items-length="totalItems"
      >
         <template v-slot:top>
            <v-toolbar flat color="secondary">
               <v-btn color="primary" @click="$refs.account.show(editedItem)"><v-icon left>mdi-plus-circle</v-icon>Nueva cuenta</v-btn>
               <v-btn color="accent" @click="initialize" icon small class="ml-2"><v-icon small>mdi-autorenew</v-icon></v-btn>
               <v-spacer></v-spacer>
               <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details></v-text-field>
            </v-toolbar>
         </template>
         <template v-slot:[`item.suscripcion`]="{ item }">
            {{ displaySuscripcion(item.suscripcion) }}
         </template>
         <template v-slot:[`item.estatus`]="{ item }">
            <v-switch flat dense readonly v-model="item.estatus" true-value="S" false-value="N" />
         </template>
         <template v-slot:[`item.microsip`]="{ item }">
            <Microsip v-if="item.microsip == 'S'" />
            <Microsip v-if="item.microsip == 'N'" style="filter: grayscale(1)" />
         </template>
         <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom>
               <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" small class="mr-2" @click="plusMonth(item.id_usuario)">mdi-plus</v-icon>
               </template>
               <span>Agregar un mes</span>
            </v-tooltip>
            <v-tooltip bottom>
               <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" small class="mr-2" @click="subtractMonth(item.id_usuario)">mdi-minus</v-icon>
               </template>
               <span>Quitar un mes</span>
            </v-tooltip>
            <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
            <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
         </template>
      </v-data-table>
   </div>
</template>

<script>
import Account from "./Accounts/Account.vue";
import Dialog from "../Dialog.vue";
import Notification from "../Notification.vue";
import moment from "moment";
import Microsip from "../Icons/Microsip.vue";

export default {
   components: {
      Account,
      Dialog,
      Notification,
      Microsip,
   },
   data: () => ({
      headers: [
         { text: "ID", value: "id_usuario" },
         { text: "Username", value: "username" },
         { text: "Nombre", value: "nombre" },
         { text: "Empresa", value: "empresa" },
         { text: "Vendedor", value: "vendedor" },
         { text: "Suscripción", value: "suscripcion" },
         { text: "Estatus", value: "estatus" },
         { text: "Microsip", value: "microsip" },
         { text: "Actions", value: "actions", sortable: false },
      ],
      rows: [],
      editedItem: {
         id_usuario: null,
         username: "",
         nombre: "",
         empresa: "",
         estatus: "S",
         vendedor: "",
         suscripcion: moment().format("YYYY-MM-DD"),
         microsip: "",
      },
      defaultItem: {
         id_usuario: null,
         username: "",
         nombre: "",
         empresa: "",
         estatus: "S",
         vendedor: "",
         suscripcion: moment().format("YYYY-MM-DD"),
         microsip: "",
      },
      options: {
         page: 1,
         itemsPerPage: 10,
         sortBy: [],
         sortDesc: [],
      },
      search: "",
      editedIndex: -1,
      loading: false,
      totalItems: 0,
      page: 1,
   }),
   computed: {
      title() {
         return this.editedIndex === -1 ? "Nueva cuenta" : "Editar cuenta";
      },
   },
   watch: {
      options: async function () {
         await this.initialize();
      },
      search: async function () {
         this.page = 1;
         await this.initialize();
      },
   },
   methods: {
      async initialize() {
         try {
            this.loading = true;
            await this.$store.dispatch("getCuentas", {
               page: this.options.page,
               itemsPerPage: this.options.itemsPerPage,
               sortBy: this.options.sortBy[0] ?? "",
               sortDesc: this.options.sortDesc[0] ?? false,
               search: this.search,
            });
            this.rows = this.$store.state.cuentas.accounts;
            this.totalItems = this.$store.state.cuentas.totalItems;
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.loading = false;
         }
      },
      async plusMonth(id_usuario) {
         await this.$store.dispatch("addMonth", {
            id: id_usuario,
            tipo: "S",
         });
         this.initialize();
      },
      async subtractMonth(id_usuario) {
         await this.$store.dispatch("addMonth", {
            id: id_usuario,
            tipo: "R",
         });
         this.initialize();
      },
      displaySuscripcion(val) {
         return moment(val).format("YYYY-MM-DD");
      },
      editItem(item) {
         this.editedIndex = this.rows.indexOf(item);
         this.editedItem = Object.assign({}, item);
         this.$refs.account.show(this.editedItem);
      },
      deleteItem(item) {
         this.editedIndex = this.rows.indexOf(item);
         this.editedItem = Object.assign({}, item);
         this.$refs.delete.Show();
      },
      async deleteItemConfirm() {
         try {
            let res = await this.$store.dispatch("deleteCuenta", { id: this.editedItem.id_usuario });
            if (res == true) {
               this.rows.splice(this.editedIndex, 1);
               this.$refs.ok_noti.Show();
            } else {
               throw res;
            }
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.close();
         }
      },
      close() {
         this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem);
            this.editedIndex = -1;
         });
      },
      async save() {
         if (this.editedIndex > -1) {
            Object.assign(this.rows[this.editedIndex], this.editedItem);
         } else {
            this.rows.push(this.editedItem);
         }
         await this.initialize();
         this.close();
      },
   },
};
</script>
