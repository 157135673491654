<template>
   <Accounts/>
</template>

<script>
import Accounts from "../components/Cuentas/Accounts.vue"

export default {
   name: "Cuentas",
   components: {
      Accounts
   }
};
</script>
